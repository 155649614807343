import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * PROCESS SUMMARY API
 */
export const GetAllProcesses = async () => {
  let url = `ProcessSummaryAPI.ashx?action=list&maxrecords=500`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetAllProcessesFinished = async () => {
  let url = `ProcessSummaryAPI.ashx?action=list&finished=true`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetProcessesByUser = async ({ Id }) => {
  let url = `ProcessSummaryAPI.ashx?action=LISTFORUSERINSTANCE&userinstanceid=${Id}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  const res: any = await dbQueue({ config, url });
  return res;
};

export const GetAssignedUsersByProcess = async ({ Id }) => {
  let url = `ProcessSummaryAPI.ashx?action=GETASSIGNEDUSERS&processinstanceid=${Id}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

interface FinishedOrUnfinishedType {
  Id: string;
  finishedDeal: boolean;
}

/**Get list of active deal or closed deals
 * @param {boolean} finishedDeal - true for closed finished deals / false for unfinished active deals /
 */
export const GetProcessByUserFinishedOrUnfinished = async ({
  Id,
  finishedDeal
}: FinishedOrUnfinishedType) => {
  let url = `ProcessSummaryAPI.ashx?action=LISTFORUSERINSTANCE&userinstanceid=${Id}&finished=${finishedDeal}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
}; //END GetProcessByUserFinishedOrUnfinished

/** GET USER STATS
 * basically returns user metric number of open / closed / abandoned / won deals */
export const GetUserStats = async () => {
  let url = `ProcessSummaryAPI.ashx?action=USERSTATS`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

// 0 50
// 1 100
// etc
export const GetLevel12Search = async ({
  query = 'test',
  action = 'customsearch',
  offkeyset = '0',
  closed
}: {
  query?: string;
  action?: string;
  offkeyset?: string;
  closed: boolean;
}) => {
  let url = `ProcessSummaryAPI.ashx?action=${action}&q=${query}&offset=${offkeyset}&closed=${closed}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};
