import { FunderDoc } from 'components/Calculator/Calculator/interfaces';

export const productDetails = ({ state }) => {
  const value = {
    id: '1',
    items: [
      {
        key: 1,
        Text1: 'Finance and Product Types',
        Text2: 'Hire Purchase -  Personal Contract Purchase'
      },
      {
        key: 2,
        Text1: 'Regulatory Status',
        Text2: 'Regulated'
      },
      {
        key: 3,
        Text1: 'CCA Status',
        Text2: 'Not Consumer'
      }
    ]
  };
  return value;
};

export const termStructure = ({ state }: { state: FunderDoc }) => {
  const fundingProfileText = state.FUNDING_PROFILE_DISPLAY
    ? `${state.FUNDING_PROFILE_DISPLAY} Instalments`
    : `${state.FUNDING_PROFILE} Instalments`;
  const value = {
    id: '2',
    items: [
      {
        key: 1,
        Text1: 'Hire Period',
        Text2: state.HIRE_PERIOD + ' Months'
      },
      {
        key: 2,
        Text1: 'Funding Profile',
        Text2: fundingProfileText
      },
      {
        key: 3,
        Text1: 'Documentation Fee payable',
        Text2: state.DOCUMENTATION_FEE_PAYABLE
      }
    ]
  };
  return value;
};

export const ratesandReturns = ({ state }: { state: FunderDoc }) => {
  const value = {
    id: '3',
    items: [
      {
        key: 1,
        Text1: 'Margin',
        Text2: parseFloat(state.MARGIN.toString()).toFixed(3) + '%'
      },
      {
        key: 2,
        Text1: 'Yield',
        Text2: parseFloat(state.YIELD.toString()).toFixed(3) + '%'
      },
      {
        key: 3,
        Text1: 'APR Including Fees',
        Text2: parseFloat(state.APR.toString()).toFixed(3) + '%'
      },
      {
        key: 4,
        Text1: 'Flat Rate',
        Text2: parseFloat(state.FLAT_RATE.toString()).toFixed(3) + '%'
      },
      {
        key: 5,
        Text1: 'Funding Rate',
        Text2: parseFloat(state.FUNDING_RATE.toString()).toFixed(3) + '%'
      },
      {
        key: 6,
        Text1: 'Nominal Flat Rate',
        Text2: parseFloat(state.NOMINAL_FLAT_RATE.toString()).toFixed(3) + '%'
      },
      {
        key: 7,
        Text1: 'Rate Of Interest Per Annum',
        Text2:
          (state.RATE_PER_ANNUM != null && !isNaN(state.RATE_PER_ANNUM)
            ? parseFloat(state.RATE_PER_ANNUM.toString()).toFixed(3)
            : '0.000') + '%'
      },
      {
        key: 7,
        Text1: 'Rate Per Thousand',
        Text2:
          state.RATE_PER_THOUSAND != null && !isNaN(state.RATE_PER_THOUSAND)
            ? parseFloat(state.RATE_PER_THOUSAND.toString()).toFixed(2)
            : '0.00'
      }
    ]
  };
  return value;
};

export const termination = ({ state, Currency }) => {
  const value = {
    id: '4',
    items: [
      {
        key: 1,
        Text1: 'Half Amount Payable',
        Text2: <Currency value={state.HALF_AMOUNT_PAYABLE} />
      },
      {
        key: 2,
        Text1: 'Third Amount Payable',
        Text2: <Currency value={state.THIRD_AMOUNT_PAYABLE} />
      }
    ]
  };
  return value;
};
